import React from 'react'

const Cookies = ({setCookie}) => {
  return (
    <div className='cookieWrapper'>
      <div className="header">
        <h5>This website uses cookies</h5>
        <p>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>
      </div>
      <div className="buttonWrap">
        <button onClick={()=>{setCookie(false)}}>Deny</button>
        <button onClick={()=>{setCookie(false)}}>Allow All</button>
      </div>
    </div>
  )
}

export default Cookies
