import React, { useState } from "react";
import "../../Scss/Layout/footer.css";
import { Link } from "react-router-dom";
import Cookies from "../../CommonComponent/Cookies";

const Footer = () => {
  const [cookie, setCookie]=useState(false)

  //setTimeout(() => setCookie(true), 5000);
  return (
    <>
      <footer>
        <div className="container">
          <div className="footerWrap">
            <div className="logo">
              <h2>SKIPASSEO</h2>
              <p>
                You want to ski, you need skipass - let us help to get it for you
              </p>
            </div>
            <div className="footerLink">
              <ul>
                <li>
                  <Link to="#">Home</Link>
                </li>
                <li>
                  <Link to="#">Links</Link>
                </li>
                <li>
                  <Link to="#">Press</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="#">Company</Link>
                </li>
                <li>
                  <Link to="#">Contact Us</Link>
                </li>
                <li>
                  <Link to="#">Legal Notice</Link>
                </li>
              </ul>
            </div>
            <div className="socialLinks">
              <h5>SOCIAL MEDIA</h5>
              <ul>
                <li>
                  <Link to="#">
                    <img
                      src={require("../../assets/layout/youtube.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={require("../../assets/layout/facebook.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={require("../../assets/layout/whatsapp.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={require("../../assets/layout/linkedin.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={require("../../assets/layout/twitter.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>© Copyright 2023 by Skippaseo</p>
        </div>
      </footer>
      {
        cookie ?<Cookies setCookie={setCookie}/>: null 
      }
      
    </>
  );
};

export default Footer;
