import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Index from "./Components/Layout/Index";
import { Suspense, lazy } from "react";
/*==========added lazy loading for the component =============*/
const Home = lazy(()=> import ('./Components/Pages/HomePage/Home'));
const HomeDetails =lazy(()=> import ('./Components/Pages/DetailsPage/HomeDetails'))

function App() {
  return (
    <>
    <Suspense fallback="Loading">
      <BrowserRouter>
        <Routes>
          <Route element={<Index />}>
            <Route path="/" element={<Home />}/>
            <Route path="/details" element={<HomeDetails />}/>
          </Route>
        </Routes>
      </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
